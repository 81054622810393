import React, { useState } from "react";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { devMode, faqsPath, PRODUCTS, PRODUCT_NAMES } from "../config";
import { onFAQsPage, replacePlaceholderWithProductCapitalized } from "./utils";
import { wizardAnswersSelector } from "../redux/wizard/selectors";
import { faqsActions } from "../redux/faqs/actions";
import { getProductFromState } from "../helpers/urlWiz";

import Content from ".";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const FAQ = ({ data, embedded = false }) => {
  const { slug, title } = data;
  const wizardAnswers = useSelector(wizardAnswersSelector);
  const { activeProduct } = useSelector((state) => state.faqs);

  const product = onFAQsPage() ? activeProduct : getProductFromState(wizardAnswers);

  return embedded ? fullFAQ(data, product) : minimalFAQ(title, slug, product);
};

const fullFAQ = ({ slug, title, MEATER, MEATER_Plus, MEATER_Block, MEATER_2_Plus, MEATER_Pro_Xl, content }, product) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(faqsPath);
  };

  return (MEATER && product === PRODUCTS.MEATER) ||
    (MEATER_Plus && product === PRODUCTS.MEATER_PLUS) ||
    (MEATER_Block && product === PRODUCTS.MEATER_BLOCK) ||
    (MEATER_2_Plus && product === PRODUCTS.MEATER_2_PLUS) ||
    (MEATER_Pro_Xl && product === PRODUCTS.MEATER_PRO_XL) ||
    onFAQsPage() ? (
    <div className="content__faq">
      {onFAQsPage() && (
        <button className="faq__button faq__button--close" onClick={handleClick}>
          <CloseIcon className="faq__icon" />
        </button>
      )}
      <Content data={{ title, content }} />
      <Feedback slug={slug} />
    </div>
  ) : null;
};

const minimalFAQ = (title, slug, product) => {
  const notOnFAQsPage = !onFAQsPage();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    history.push(`${faqsPath}/${slug}`);
    notOnFAQsPage && dispatch(faqsActions.setProduct(product));
  };

  return (
    title && (
      <div className="content__minimal-faq" onClick={handleClick}>
        <h3>{replacePlaceholderWithProductCapitalized(title, PRODUCT_NAMES[product])}</h3>
      </div>
    )
  );
};

const Feedback = ({ slug }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [gaveFeedback, setGaveFeedback] = useState(false);

  const sendFaqEvent = (answer) => {
    setGaveFeedback(true);
    const event = {
      action: answer,
      label: `${slug}`,
      category: "support-wizard",
    };
    !devMode && ReactGA.event(event);
  };

  return (
    <div className="feedback-wrapper">
      {gaveFeedback ? (
        <p className="feedback__text feedback__text--thank-you" onClick={() => history.push(faqsPath)}>
          {t("thank-you-for-feedback")}
        </p>
      ) : (
        <>
          <p className="feedback__text">{t("did-this-help")}</p>
          <div className="thumbs-wrapper">
            <button className="faq__button">
              <ThumbUpIcon onClick={() => sendFaqEvent("yes")} className="faq__icon thumbs__icon thumbs__icon--green" />
            </button>
            <button className="faq__button">
              <ThumbDownIcon onClick={() => sendFaqEvent("no")} className="faq__icon thumbs__icon thumbs__icon--red" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FAQ;
