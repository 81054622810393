import React from "react";
import MEATER from "./assets/MEATER.svg";
import MEATER_BLOCK from "./assets/MEATER_BLOCK.svg";
import MEATER_PLUS from "./assets/MEATER_PLUS.svg";
import MEATER_2_PLUS from "./assets/MEATER_2_PLUS.svg";
import MEATER_PRO_XL from "./assets/MEATER_PRO_XL.svg";

import FlagAU from "./assets/aus-nz.svg";
import FlagCA from "./assets/ca.svg";
import FlagDEAT from "./assets/de-at.svg";
import FlagEU from "./assets/eu.svg";
import FlagIntl from "./assets/row.svg";
import FlagUK from "./assets/uk.svg";
import FlagUS from "./assets/us.svg";

import {
  ZendeskForm,
  DisclaimerZendeskForm,
  OrderCancellationChangesForm,
  OrderOtherZendeskForm,
  DamagedProductForm,
} from "./zendesk-form";
import { ORDER_METHODS, AfterShipReturnsForms } from "./zendesk-form/AfterShipReturnsForm";
import { OrderChecker } from "./order-checker";

export const NONCE = "2.13.1";

const liveUrls = ["support.meater.com"];
export const devMode = !liveUrls.includes(window.location.hostname);
export const basePath = "/";
export const wizardPath = "/helper";
export const orderCheckerPath = "/helper?how-can-we-help=orders-returns&orders-and-returns=order-checker";
export const cancellationChangesPath = "/helper?how-can-we-help=orders-returns&orders-and-returns=cancel";
export const ordersContactUsEscalation = `/helper?how-can-we-help=orders-returns&orders-and-returns=other-request`;
export const cmsPath = "/cms";
export const thankYouPath = "/thank-you";
export const thankYouPathMarketing = "/thank-you-marketing";
export const happyCookingPath = "/happy-cooking";
export const faqsPath = "/faqs";
export const contactUsPath = "/contact-us";
export const businessContactUs = `${contactUsPath}/business-inquiries`;
export const influencerContactUs = `${contactUsPath}/press-influencer`;
export const technicalContactUs = `${contactUsPath}/technical`;
export const zendeskContactUs = `${contactUsPath}/zendesk-form`;
export const orderCheckerContactUs = `${contactUsPath}/order/order-checker`;
export const cancellationChangesContactUs = `${contactUsPath}/order/cancellation-changes`;
export const liveChatPath = "/live-chat";
export const traegerSupportPath = "/traeger-support-form";
export const resellerSupportPath = "/reseller-support";

export const PRODUCTS = {
  MEATER: "meater",
  MEATER_PLUS: "meater-plus",
  MEATER_BLOCK: "meater-block",
  MEATER_2_PLUS: "meater-2-plus",
  MEATER_PRO_XL: 'meater-pro-xl'
};

export const PRODUCT_NAMES = {
  [PRODUCTS.MEATER]: "MEATER",
  [PRODUCTS.MEATER_PLUS]: "MEATER Plus",
  [PRODUCTS.MEATER_BLOCK]: "MEATER Block",
  [PRODUCTS.MEATER_2_PLUS]: "MEATER 2 Plus",
  [PRODUCTS.MEATER_PRO_XL]: "MEATER Pro Xl",

};

export const PRODUCT_LIST = [
  { name: PRODUCTS.MEATER, image: MEATER },
  { name: PRODUCTS.MEATER_PLUS, image: MEATER_PLUS },
  { name: PRODUCTS.MEATER_BLOCK, image: MEATER_BLOCK },
  { name: PRODUCTS.MEATER_2_PLUS, image: MEATER_2_PLUS },
  { name: PRODUCTS.MEATER_PRO_XL, image: MEATER_PRO_XL },
];

export const CUSTOMER_SUPPORT_SERVICES = {
  LIVE_CHAT: "liveChat",
  CALL_BACK: "callBack",
};

export const ZENDESK_TICKET = {
  TECHNICAL: "TS",
  TRAEGER_RAISED: "TRSWF",
  TITLE_PARTS_SEPARATOR: "-",
};

export const ZENDESK_LIVE_CHAT = {
  SCIRPT_ID: "ze-snippet",
  WIDGET_ID: "webWidget",
  LAUNCHER_ID: "launcher",
  SCRIPT_SRC: "https://static.zdassets.com/ekr/snippet.js?key=c039567f-67ed-40d3-8597-3501f2892c1a",
  SETTINGS: {
    webWidget: {
      color: { theme: "#C8102E" },
      chat: {
        title: {
          "*": "MEATER Live Chat",
        },
      },
    },
  },
};

export const ANSWER_SLUG_TO_ZENDESK_TICKET_TITLE = {
  ALIAS_KEY: "slugAlias",
  POSITION_KEY: "slugPosition",
  RESPONSE_KEY: "zendeskTicketTitle",
};

export const ATTACHMENTS = {
  UPLOAD_FAILED_TOKEN: null,
  COUNT_LIMIT: 10,
  SIZE_LIMIT: 10485760, // 10MB
  SIZE_LIMIT_IN_MB: 10485760 / 1024 / 1024,
  SUPPORTED_FORMATS: ["jpe", "jpg", "jpeg", "png", "heic"],
};

export const SLUGS = {
  HAPPY_COOKING: "happy-cooking",
  CONTACT_FORM: "contact-form",
  ORDER_STATUS_FORM: "order-status-form",
  DISCLAIMER_FORM: "disclaimer-form",
  AFTERSHIP_RETURNS_FROM_US: "aftership-returns-form-us",
  AFTERSHIP_RETURNS_FROM_UK: "aftership-returns-form-uk",
  AFTERSHIP_RETURNS_FROM_EU: "aftership-returns-form-eu",
  AFTERSHIP_RETURNS_FROM_AU_NZ: "aftership-returns-form-au-nz",
  AFTERSHIP_RETURNS_FROM_CA: "aftership-returns-form-ca",
  AFTERSHIP_RETURNS_FROM_DE_AT: "aftership-returns-form-de-at",
  AFTERSHIP_RETURNS_FROM_INTL: "aftership-returns-form-intl",
  ISSUE_RESOLVED: "yes",
  ISSUE_NOT_RESOLVED: "no",
  ORDER_CHECKER_SUPPORTED_STORES_FORM: "order-checker-supported-stores-form",
  ORDER_CHECKER_UNSUPPORTED_STORES_FORM: "order-checker-unsupported-stores-form",
  ORDER_CANCELLATION_CHANGES_FORM: "order-cancellation-changes-form",
  ORDER_OTHER_DISCLAIMER_FORM: "order-other-disclaimer-form",
  DAMAGED_PRODUCT_FORM: "damaged-product-form",
};
export const WIZARD_END = {
  PARAMS: {
    HAPPY_COOKING: "happycooking",
    WEBFORM: "webform",
  },
  SLUGS: [
    SLUGS.HAPPY_COOKING,
    SLUGS.CONTACT_FORM,
    SLUGS.ORDER_STATUS_FORM,
    SLUGS.DISCLAIMER_FORM,
    SLUGS.AFTERSHIP_RETURNS_FROM_US,
    SLUGS.AFTERSHIP_RETURNS_FROM_UK,
    SLUGS.AFTERSHIP_RETURNS_FROM_EU,
    SLUGS.AFTERSHIP_RETURNS_FROM_AU_NZ,
    SLUGS.AFTERSHIP_RETURNS_FROM_CA,
    SLUGS.AFTERSHIP_RETURNS_FROM_DE_AT,
    SLUGS.AFTERSHIP_RETURNS_FROM_INTL,
    SLUGS.ORDER_CHECKER_SUPPORTED_STORES_FORM,
    SLUGS.ORDER_CHECKER_UNSUPPORTED_STORES_FORM,
    SLUGS.ORDER_CANCELLATION_CHANGES_FORM,
    SLUGS.ORDER_OTHER_DISCLAIMER_FORM,
    SLUGS.DAMAGED_PRODUCT_FORM,
  ],
};
export const SLUG_TO_WIZARD_END_PARAM = {
  [SLUGS.HAPPY_COOKING]: WIZARD_END.PARAMS.HAPPY_COOKING,
  [SLUGS.CONTACT_FORM]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.ORDER_STATUS_FORM]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.DISCLAIMER_FORM]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.AFTERSHIP_RETURNS_FROM_US]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.AFTERSHIP_RETURNS_FROM_UK]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.AFTERSHIP_RETURNS_FROM_EU]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.AFTERSHIP_RETURNS_FROM_AU_NZ]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.AFTERSHIP_RETURNS_FROM_CA]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.AFTERSHIP_RETURNS_FROM_DE_AT]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.AFTERSHIP_RETURNS_FROM_INTL]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.ORDER_CHECKER_SUPPORTED_STORES_FORM]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.ORDER_CHECKER_UNSUPPORTED_STORES_FORM]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.ORDER_CANCELLATION_CHANGES_FORM]: WIZARD_END.PARAMS.WEBFORM,
  [SLUGS.ORDER_OTHER_DISCLAIMER_FORM]: WIZARD_END.PARAMS.WEBFORM,
};
export const FORMS = {
  1: {
    id: 1,
    slug: SLUGS.CONTACT_FORM,
    title: "Contact Form",
    component: ZendeskForm,
  },
  2: {
    id: 2,
    slug: SLUGS.DISCLAIMER_FORM,
    title: "Disclaimer Form",
    component: DisclaimerZendeskForm,
  },
  3: {
    id: 3,
    slug: SLUGS.AFTERSHIP_RETURNS_FROM_US,
    title: "AfterShip Returns Form US",
    component: AfterShipReturnsForms[ORDER_METHODS.US],
  },
  4: {
    id: 4,
    slug: SLUGS.AFTERSHIP_RETURNS_FROM_UK,
    title: "AfterShip Returns Form UK",
    component: AfterShipReturnsForms[ORDER_METHODS.UK],
  },
  5: {
    id: 5,
    slug: SLUGS.AFTERSHIP_RETURNS_FROM_EU,
    title: "AfterShip Returns Form EU",
    component: AfterShipReturnsForms[ORDER_METHODS.EU],
  },
  6: {
    id: 6,
    slug: SLUGS.AFTERSHIP_RETURNS_FROM_AU_NZ,
    title: "AfterShip Returns Form AU / NZ",
    component: AfterShipReturnsForms[ORDER_METHODS.AU_NZ],
  },
  7: {
    id: 7,
    slug: SLUGS.AFTERSHIP_RETURNS_FROM_CA,
    title: "AfterShip Returns Form CA",
    component: AfterShipReturnsForms[ORDER_METHODS.CA],
  },
  8: {
    id: 8,
    slug: SLUGS.AFTERSHIP_RETURNS_FROM_DE_AT,
    title: "AfterShip Returns Form DE / AT",
    component: AfterShipReturnsForms[ORDER_METHODS.DE_AT],
  },
  9: {
    id: 9,
    slug: SLUGS.AFTERSHIP_RETURNS_FROM_INTL,
    title: "AfterShip Returns Form INTL",
    component: AfterShipReturnsForms[ORDER_METHODS.INTL],
  },
  10: {
    id: 10,
    slug: SLUGS.ORDER_CHECKER_SUPPORTED_STORES_FORM,
    title: "Order Checker Supported Stores Form",
    component: (props) => <OrderChecker {...props} />,
  },
  11: {
    id: 11,
    slug: SLUGS.ORDER_CHECKER_UNSUPPORTED_STORES_FORM,
    title: "Order Checker Unsupported Stores Form",
    component: (props) => <OrderChecker {...props} supportedStore={false} />,
  },
  12: {
    id: 12,
    slug: SLUGS.ORDER_CANCELLATION_CHANGES_FORM,
    title: "Order Cancellation / Changes Form",
    component: OrderCancellationChangesForm,
  },
  13: {
    id: 13,
    slug: SLUGS.ORDER_OTHER_DISCLAIMER_FORM,
    title: "Order Other Disclaimer Form",
    component: OrderOtherZendeskForm,
  },
  14: {
    id: 14,
    slug: SLUGS.DAMAGED_PRODUCT_FORM,
    title: "Damaged Product Form",
    component: DamagedProductForm,
  },
};

export const SHOPS = [
  {
    icon: FlagUS,
    href: "https://store-us.meater.com/account",
    alt: "USA Flag",
    text: "USA Store",
  },
  {
    icon: FlagCA,
    href: "https://store-ca.meater.com/account",
    alt: "Canada Flag",
    text: "CA Store",
  },
  {
    icon: FlagDEAT,
    href: "https://store-de.meater.com/account",
    alt: "DE and AT Flags",
    text: "DE & AT Store",
  },
  {
    icon: FlagUK,
    href: "https://store-uk.meater.com/account",
    alt: "UK Flag",
    text: "UK Store",
  },
  {
    icon: FlagEU,
    href: "https://store-eu.meater.com/account",
    alt: "EU Flag",
    text: "EU Store",
  },
  {
    icon: FlagAU,
    href: "https://store-au.meater.com/account",
    alt: "Australia & New Zealand Flags",
    text: "AUS & NZ",
  },
];

let contentUrl = "";
let hostNameUrl = "";
let recaptchaSiteKey1 = "";

(function () {
  if (window.location.hostname === "support.meater.com") {
    contentUrl = "https://content.support.meater.com";
    hostNameUrl = `https://${window.location.hostname}`;
    recaptchaSiteKey1 = "6Le8aUEjAAAAACqqqjsRxh0AoegJdncDjtWrKKfB";
  } else if (window.location.hostname === "localhost") {
    contentUrl = "http://localhost:18100";
    hostNameUrl = "http://localhost:18101";
    recaptchaSiteKey1 = "6LeQKXEiAAAAADrnk6w03Mxm9CK0Gx1y8-VcpL10";
  } else {
    contentUrl = "https://content.egg.meater.com";
    hostNameUrl = `https://${window.location.hostname}`;
    recaptchaSiteKey1 = "6LeQKXEiAAAAADrnk6w03Mxm9CK0Gx1y8-VcpL10";
  }
})();

export const strapiURL = contentUrl;
export const API_URL = hostNameUrl;
export const zendeskEndpoint = `${hostNameUrl}/create-ticket`;
export const zendeskUploadsEndpoint = "https://apptionlabs.zendesk.com/api/v2/uploads.json?filename=";
export const SUPPORT_CMS_ENDPOINTS = {
  LOGIN: `${hostNameUrl}${cmsPath}/login`,
  FORGOT_PASSWORD: `${hostNameUrl}${cmsPath}/forgot-password`,
  RESET_PASSWORD: `${hostNameUrl}${cmsPath}/reset-password`,
};
export const TRAEGER_SUPPORT_ENDPOINTS = {
  LOGIN: `${hostNameUrl}${traegerSupportPath}/login`,
  FORGOT_PASSWORD: `${hostNameUrl}${traegerSupportPath}/forgot-password`,
  RESET_PASSWORD: `${hostNameUrl}${traegerSupportPath}/reset-password`,
};
export const ORDER_CHECKER_ENDPOINTS = {
  STATUS: `${hostNameUrl}/orders/status`,
  SHIPMENT_STATUS: `${hostNameUrl}/orders/shipment-status`,
  RESHIPMENT: `${hostNameUrl}/orders/reshipment`,
  QUESTIONS: `${hostNameUrl}/orders/questions`,
};
export const ORDER_CHECKER_OTHER_STORES_PATHS = [
  "/helper?how-can-we-help=orders-returns&orders-and-returns=order-checker&order-method=oc-amazon",
  "/helper?how-can-we-help=orders-returns&orders-and-returns=order-checker&order-method=oc-other-stores",
];
export const recaptchaSiteKey = recaptchaSiteKey1;
